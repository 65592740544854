$primary_color: #f95959;
$secondary_color: white;
$tertiary_color: rgb(245, 250, 250);
$error_color: tomato;
$success_color: seagreen;
$border_primary_color: #c4c4c4;
$border_secondary_color: #212121;
$border_radius: 5px;
$border_thin: 1px;
$border_thick: 2px;
$text_area_height: 120px;
$font_size_base: 16px;
$font_size_small: 14px;
$font_size_big: 18px;
$font_size_bigger: 24px;
$padding_base: 10px;
$padding_small: $padding_base/2;
$responsive_min_width: 300px;
$content_max_width: 750px;
html {
  height: -webkit-fill-available;
}
body {
  font-family: sans-serif;
  background-color: white;
  min-height: 100vh;
}
// input で入力がある時に背景色が薄い青色になるのを背景色を白にすることで防ぐ
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}
// ボタン
@mixin btn($color, $background_color) {
  display: inline-block;
  width: 72px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: $background_color;
  border: $border_thick solid transparent;
  border-radius: $border_radius;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: $color;
  font-size: $font_size_base;
  text-align: center;
  text-decoration: none;
  transition: 0.25s;
  border-style: solid;
}
@mixin btn_hover($color, $background_color) {
  &:hover,
  :focus {
    background-color: $background_color;
    border-color: currentColor;
    color: $color;
  }
}
// テキストボックス
@mixin text_box($border_color, $border: $border_thin) {
  width: 100%;
  padding: 8px 12px;
  font-size: $font_size_base;
  border: $border solid $border_color;
  border-radius: $border_radius;
  box-sizing: border-box;
}
@mixin text_box_hover($hover_color, $focus_color) {
  &:hover {
    border-color: $hover_color;
    border: $border_thin solid $hover_color;
  }
  &:focus {
    border-color: $focus_color;
    border: $border_thick solid $focus_color;
    outline: 0;
  }
}
// チェックボックス
.checkbox {
  display: none;
}
.checkbox_text {
  padding-left: 20px;
  position: relative;
}
.checkbox_text::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  border: $border_thin solid $border_primary_color;
  border-radius: $border_radius;
}
.checkbox:checked + .checkbox_text::before {
  background-color: $primary_color;
}
.checkbox:checked + .checkbox_text::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  transform: rotate(40deg);
  border-bottom: 2px solid $secondary_color;
  border-right: 2px solid $secondary_color;
}
// 検索
.search__btn {
  @include btn($secondary_color, $primary_color);
  @include btn_hover($primary_color, $secondary_color);
}
.search__send_process_btn {
  @include btn($secondary_color, $primary_color);
}
.search__text_box {
  @include text_box($border_primary_color);
  @include text_box_hover($border_secondary_color, $primary_color);
}
// 検索_料金用テキストボックスなど
.search__text_box_price {
  @extend .search__text_box;
  padding-left: 24px;
  width: 300/2.3;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
  &::placeholder {
    color: $border_primary_color;
  }
}
.search__price_lap {
  color: $border_primary_color;
  width: 100%;
  position: relative;
  &::before {
    content: "￥";
    position: absolute;
    top: 10px;
    left: 5px;
    font-size: $font_size_base;
  }
  &:hover {
    color: $border_secondary_color;
  }
}
.search__price_tilde {
  margin: auto;
  text-align: center;
  transform: rotate(90deg);
}
.search__price_flex {
  display: flex;
  flex-direction: column;
}
@media (min-width: $responsive_min_width) {
  .search__price_tilde {
    transform: rotate(0deg);
    position: static;
  }
  .search__price_flex {
    flex-direction: row;
  }
}

.search__block {
  display: block;
  padding-top: $padding_small;
  padding-bottom: $padding_small;
  width: 100%;
}
.search__item_name {
  font-size: $font_size_base;
  font-weight: bold;
}
.search__label_block {
  display: inline-block;
  padding-top: $padding_small;
  padding-bottom: $padding_small;
}
.search__checkbox_block {
  width: 100%;
  font-size: $font_size_small;
}
@media (min-width: $responsive_min_width) {
  .search__checkbox_block {
    width: 48%;
  }
  .search__checkbox_block:nth-child(2n + 1) {
    clear: both;
  }
}
.search {
  width: 100%;
  padding: $padding_base;
  background: $secondary_color;
  overflow-x: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100%;
}
@media (min-width: $responsive_min_width) {
  .search {
    width: $responsive_min_width;
  }
}
.search__default_position {
  position: absolute;
  transform: translateX(0px);
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0x;
}
.search__trans_position {
  @extend .search__default_position;
  transform: translateX(-$responsive_min_width);
}
// 冒頭の大きい文字
.search__title {
  font-size: $font_size_big;
  font-weight: bold;
  padding: 10px;
}
// 検索期間
.search__date_flex {
  @extend .search__price_flex;
}
.search__date_tilde {
  @extend .search__price_tilde;
}
// 検索内のコンポーネントに対する就職
.search__centering {
  text-align: center;
}
// 検索を表示したり消したりするボタン
.search__show_control_btn {
  position: absolute;
}
// ホールコンポーネント
.hall {
  width: 100%;
  padding: $padding_base;
  background-color: $tertiary_color;
  overflow-x: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100%;
}
@media (min-width: $responsive_min_width) {
  .hall {
    width: $responsive_min_width;
  }
}
.hall__hall_name {
  text-align: center;
  font-size: $font_size_big;
  font-weight: bold;
  padding-top: $padding_base;
  padding-left: 20px;
  padding-right: 20px;
}
.hall__concert_name {
  font-size: $font_size_base;
  font-weight: bold;
  padding: $padding_base;
}
.hall__item_name {
  font-size: $font_size_base;
  font-weight: bold;
}
.hall__item_block {
  padding-top: $padding_small;
  padding-bottom: $padding_small;
}
.hall__small_text_block {
  font-size: $font_size_small;
  padding-top: 5px;
}
.hall__indent_text {
  padding-left: $font_size_small;
}
.hall__centering {
  text-align: center;
}
.hall__image {
  max-width: 100%;
}
.hall__default_position {
  position: absolute;
  right: 0px;
  transform: translateX(0px);
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0x;
}
.hall__trans_position {
  @extend .hall__default_position;
  transform: translateX($responsive_min_width);
}

// 検索を表示したり消したりするボタン
.hall__show_control_btn {
  position: absolute;
  right: 5px;
}
// 問い合わせ
.contact__text_area {
  @include text_box($border_primary_color, $border_thin);
  @include text_box_hover($border_secondary_color, $primary_color);
  height: $text_area_height;
  &:invalid:not(:focus) {
    border: $error_color 2px solid;
  }
}
.contact__email_text_box {
  @include text_box($border_primary_color, $border_thin);
  @include text_box_hover($border_secondary_color, $primary_color);
  &:invalid:not(:focus) {
    border: $error_color 2px solid;
  }
}
.contact__btn {
  @include btn($secondary_color, $primary_color);
  @include btn_hover($primary_color, $secondary_color);
}
.contact__error_btn {
  @include btn($secondary_color, $error_color);
  @include btn_hover($error_color, $secondary_color);
}
.contact__success_btn {
  @include btn($secondary_color, $success_color);
}
.contact__send_process_btn {
  @include btn($secondary_color, $primary_color);
}
.contact {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: $secondary_color;
  z-index: 2;
  overflow-x: scroll;
  overflow-x: hidden;
}
.contact__contents {
  width: 75%;
  padding-top: 96px;
  padding-bottom: 36px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: $content_max_width) {
  .contact__contents {
    width: $content_max_width * 0.75;
  }
}
.contact__header {
  font-size: $font_size_bigger;
  font-weight: bold;
  padding-bottom: 16px;
}
.contact__item_block {
  padding-top: $padding_base;
  padding-bottom: $padding_base;
}
.contact__item_name {
  font-size: $font_size_big;
  font-weight: bold;
  padding-bottom: $padding_small;
}
.contact__necessary_text {
  font-size: $font_size_small;
  color: $error_color;
}
.contact__centering {
  text-align: center;
}
// サイトについて
.about {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: $secondary_color;
  z-index: 2;
  overflow-x: scroll;
  overflow-x: hidden;
}
.about__contents {
  width: 75%;
  padding-top: 96px;
  padding-bottom: 36px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: $content_max_width) {
  .about__contents {
    width: $content_max_width * 0.75;
  }
}
.about__header {
  font-size: $font_size_bigger;
  font-weight: bold;
  padding-bottom: 16px;
}
.about__item_block {
  padding-top: $padding_base;
  padding-bottom: $padding_base;
}
.about__item_name {
  font-size: $font_size_big;
  font-weight: bold;
  padding-bottom: $padding_small;
}
