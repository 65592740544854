@charset "UTF-8";
html {
  height: -webkit-fill-available;
}

body {
  font-family: sans-serif;
  background-color: white;
  min-height: 100vh;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}

